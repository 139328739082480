import React from 'react'
import { Link } from "gatsby"

// components
import Violator from './violator'

// images
import hero_img from '../../images/homepage/landing_page_hero.png'

// styles
import './homepage.css'

const Homepage = () => {
  return (
    <div className="homepage_outer_div">
      <div className="grad"></div>
      {/* <Violator /> */}
      <div className="homepage_hero">
          <div className="homepage_left_container">
            <p><strong><i>Your patients deserve a 2nd chance with VASCEPA</i></strong></p>
          </div>
          <div className="homepage_right_container">
            <div className="top_container vascepa-blue">
              <h3>He's planning a</h3>
              <h1 className="vascepa-blue">2<sup className='bold'>ND</sup> HONEYMOON.</h1>
              <h3>Not a</h3>
              <h1 className="vascepa-blue">2<sup className='bold'>ND</sup> MI OR STROKE.</h1>
              <p className="vasecepa_blue fw-normal">
                Starting VASCEPA with a statin after a CV event 
                may help significantly <span className='fw-bold'>reduce the risk of the next CV event,<span className="no-bold">*</span> such as MI or stroke.</span><sup>1-4&dagger;</sup> 
              </p>
              <Link className="cta-btn hero_cta mobile-d-none" to="/outcomes-data">
                {" "}
                See data
              </Link>
              <div className="desktop-d-none">
                <Link className="cta-btn" to="/outcomes-data">
                  {" "}
                  See data 
                </Link>
                <p className="vascepa-blue mobile-p-y-10 bold"><em>Your patients deserve a 2nd chance with VASCEPA</em></p>
              </div>
              <p className="narrow-copy vascepa-blue p-t-5 mobile-tx-5 bold hang">
                <sup>&dagger;</sup>For adults on maximally tolerated statins with TG ≥150 mg/dL and established  CVD or diabetes and ≥2 CVD risk factors.
              </p>
            </div>
            <div className="bottom_container">
              <div className="bottom_1_div">
                <p className="bold m-t-0">BlinkRx may help your patients<br/> find savings on VASCEPA</p>
                <p className="narrow-copy vascepa-blue p-t-5 m-b-0 mobile-m-b-20">
                  Indicate "DAW-1" when prescribing to BlinkRx. 
                </p>
              </div>
              <div className="bottom_2_div">
                <Link className="cta-btn hero_cta mobile-m-t-0" to="/get-vascepa-now/">
                  {" "}
                  Learn more
                </Link>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Homepage