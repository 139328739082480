import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Home from '../components/home/Home'
import LeavingSiteModal from '../components/modal//LeavingSiteModal'
import LayoutFullWidth from '../components/LayoutFullWidth'
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import SEO from "../components/seo"

import Loader from "../components/loader"
import Homepage from "../components/homepage/homepage"


const HomeRefs= (props) => {
   return (
      <>
        <p className="cv-footnote">CV=cardiovascular; CVD=cardiovascular disease; DAW=dispense as written; MI=myocardial infarction; TG=triglyceride.</p>
        <p className="cv-footnote" style={{textIndent: "-5px"}}>*Cardiovascular events including myocardial infarction, stroke, coronary revascularization, and unstable angina requiring hospitalization.</p>
        <p className="cv-footnote">
          <strong>References: 1. </strong>VASCEPA [package insert]. Bridgewater, NJ: Amarin Pharma, Inc.; 2021.
          <strong> 2. </strong>Bhatt DL, Steg PG, Miller M, et al; for the REDUCE-IT Investigators. Cardiovascular risk reduction with icosapent ethyl for hypertriglyceridemia. <em>N Engl J Med</em>. 2019;380(1):11-22.
          <strong> 3. </strong>Bhatt DL, Steg PG, Miller M, et al. Reduction of cardiovascular events with icosapent ethyl–intervention trial. Presented at: American Heart Association Scientific Sessions; November 10-12, 2018; Chicago, IL.
          <strong> 4. </strong>Bhatt DL, Steg PG, Miller M, et al; on behalf of the REDUCE-IT Investigators. Effects of icosapent ethyl on total ischemic events: from REDUCE-IT. <em>J Am Coll Cardiol</em>. 2019;73(22):2791-2802.
        </p>
      </>
    )
}

export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false,
      refs: 'home'
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;

      this.setState({
        isMobile: bool,
      })
    }
  }

  render(){
    return(
    	<>
        <Loader>
          <SEO
            title="The Next Era of CV Risk Reduction"
            keywords={[""]}
            description="Significantly reduce CV risk with unprecedented results. Official HCP Website. Learn
                about reducing cardiovascular risk with VASCEPA, the REDUCE-IT® trial results, getting
                patients started on treatment, and more."/>
          <meta charSet="utf-8" />
          <link rel="canonical" href="http://www.vascepahcp.com" />

        <LayoutFullWidth refs={this.state.refs}>
          	{/* <Home isMobile={this.state.isMobile}/> */}
            <Homepage/>
        </LayoutFullWidth>
        <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          <div className="ref-outer">
        <div className="footnotes-container">
          <div className='references'>
              <HomeRefs />
          </div>
        </div>
        </div>
        <Footer />
        </Loader>
    	</>
    )
  }
}
